import Types from "./types";
import { b2cBffListingAU, ListingServiceAU, ListingServiceAUV2 } from "../../../service";
import { getSanitizedFilterData } from "../../../utils/helpers/category-helper";
import parseCookie from "../../../utils/helpers/parse-cookie";
import { AU_RECENTLY_VIEWD_KEY, COOKIE_SEARCH_KEYWORDS, RECENTLY_APPLIED_FILTERS, LISTING_TYPE, FILTER_ENTITIES, NUMBER, AU_RECENTLY_VIEWD_TIMESTAMP } from "../../../constants/app-constants";
import Filters from "../../../utils/filters-v2";
import { getAppliedFilterValues } from "../../../utils/helpers/filter-values";
import { CASED_EXPERMIMENT_TYPE } from "../../../constants/optimize-constants";
import { getRecentViewedWithTimestamp } from "../../../utils/helpers/recently-viewed";

const fetchFiltersInit = () => ({
    type: Types.FETCH_FILTERS
});

const fetchFilterSuccess = (data) => ({
    type: Types.FETCH_FILTERS_SUCCESS,
    data
});

const fetchFiltersFailure = () => ({
    type: Types.FETCH_FILTERS_FAILURE
});

const fetchFilters = () => (dispatch, getState) => {
    dispatch(fetchFiltersInit());
    const { user: { secureToken, userGeoData = {}}, abExperiment: {webPriceDropMsite} } = getState();

    const {data: webPriceDropMsiteVariant} = webPriceDropMsite;
    const queryParams = webPriceDropMsiteVariant ? `experimentVariant=${CASED_EXPERMIMENT_TYPE[webPriceDropMsiteVariant]}` : "";
    return ListingServiceAUV2.fetchFilters(secureToken, userGeoData, queryParams)
        .then((response) => {
            dispatch(fetchFilterSuccess(response.data));
            return Promise.resolve(response.data);
        })
        .catch(() => {
            dispatch(fetchFiltersFailure());
            return Promise.reject();
        });
};

const updateSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

const updateFilterTimestamp = (lastUpdated) => ({
    type: Types.UPDATE_FILTER_TIMESTAMP,
    lastUpdated
});

const updateSelectedOnScreenFilter = (selectedOnScreenFilter, activeTab) => ({
    type: Types.UPDATE_SELECTED_ON_SCREEN_FILTER,
    selectedOnScreenFilter,
    activeTab
});

const updateSelectedFilterSuggestionToDefault = () => ({
    type: Types.UPDATE_SELECTED_FILTER_SUGGESTION_TO_DEFAULT
});

const updatePriceFilterType = (priceFilterType) => ({
    type: Types.UPDATE_SELECTED_ON_SCREEN_FILTER,
    priceFilterType
});

const updateFilterOptionData = (data) => ({
    type: Types.UPDATE_FILTER_OPTION_DATA,
    data
});

const updateAllFiltersVisibility = (showAllFilters) => ({
    type: Types.UPDATE_ALL_FILTERS_VISIBILITY,
    showAllFilters
});

const fetchListingCountInit = () => ({
    type: Types.FETCH_LISTING_COUNT
});

const fetchListingCountSuccess = (data) => ({
    type: Types.FETCH_LISTING_COUNT_SUCCESS,
    data
});

const fetchListingCountFailure = () => ({
    type: Types.FETCH_LISTING_COUNT_FAILURE
});

const fetchListingCount = (params, queryString) => (dispatch, getState) => {
    dispatch(fetchListingCountInit());
    const { user: { secureToken } } = getState();
    ListingServiceAUV2.fetchListingV2(params, queryString, secureToken)
        .then((response) => {
            dispatch(fetchListingCountSuccess(response.data));
        })
        .catch(() => {
            dispatch(fetchListingCountFailure());
        });
};

const updateFilterTabType = (activeFilterTab) => ({
    type: Types.UPDATE_FILTER_TAB_TYPE,
    activeFilterTab
});

const updateSelectedSort = (selectedSort) => ({
    type: Types.UPDATE_SELECTED_SORT,
    selectedSort
});

const clearAllFilters = () => ({
    type: Types.CLEAR_ALL_FILTERS
});

const updateAppliedFilters = (appliedFilters) => ({
    type: Types.UPDATE_APPLIED_FILTERS,
    appliedFilters
});

const updateAppliedSuggestions = () => ({
    type: Types.UPDATE_APPLIED_SUGGESTIONS
});

const updateSelectedSuggestions = (data) => ({
    type: Types.UPDATE_SELECTED_SUGGESTIONS,
    data
});

const removeFilterBatch = (data) => ({
    type: Types.REMOVE_FILTER_BATCH,
    data
});

const updateUrlOptions = (urlOptions) => ({
    type: Types.UPDATE_URL_OPTIONS,
    urlOptions
});

const clearSuggestions = (filterKey) => ({
    type: Types.CLEAR_SUGGESTIONS,
    filterKey
});

const updateAllSubFilterOptions = ({ filterKey, optionKey, isSelected }) => ({
    type: Types.UPDATE_ALL_SUB_FILTER_OPTIONS,
    filterKey,
    optionKey,
    isSelected
});

const fetchCategoryFilterInit = () => ({
    type: Types.FETCH_CATEGORY_FILTER_INIT,
    categoryLoading: true
});

const fetchCategoryFiltersSuccess = (data) => ({
    type: Types.FETCH_CATEGORY_FILTER_SUCCESS,
    data
});

const fetchCategoryFiltersFailure = () => ({
    type: Types.FETCH_CATEGORY_FILTER_FAILURE,
    data: true
});

const updateAllSelectedFilterOptions = (data) => ({
    type: Types.UPDATE_ALL_SELECTED_FILTER_OPTIONS,
    data
});

const updateSelectedCategories = (data) => ({
    type: Types.UPDATE_SELECTED_CATEGORIES,
    data
});

const fetchCategoryFilters = () => (dispatch) => {
    dispatch(fetchCategoryFilterInit());
    ListingServiceAU.fetchCategories()
        .then((response) => {
            const { categoryFilterDetails } = response.data;
            const sanitizedCategory = getSanitizedFilterData(categoryFilterDetails);
            dispatch(fetchCategoryFiltersSuccess(sanitizedCategory));
        })
        .catch(() => {
            dispatch(fetchCategoryFiltersFailure());
        });
};

const fetchSearchPageInit = () => ({
    type: Types.FETCH_SEARCH_PAGE_INIT,
    loadingSearch: true
});

export const fetchSearchPagesSuccess = (data) => ({
    type: Types.FETCH_SEARCH_PAGE_SUCCESS,
    data
});

const fetchSearchPagesFailure = () => ({
    type: Types.FETCH_SEARCH_PAGE_FAILURE

});

const updateSSRFiltersList = (data) => ({
    type: Types.UPDATE_SSR_FILTERS_LIST,
    data
});

// eslint-disable-next-line max-statements
const fetchSearchResult = (payload = {}, params = "", isSilentLoad = false) => async (dispatch, getState) => {
    const {
        user: { secureToken },
        filters: {
            allFilters,
            appliedFilters = []
        },
        preApprovalLoan: {
            preApproved
        },
        cities: {
            selectedCity: {
                code: selectedCityCode
            } = {}
        } } = getState();
    if (!isSilentLoad) {
        dispatch(fetchSearchPageInit());
    }
    const searchedKeyword = JSON.parse(parseCookie(COOKIE_SEARCH_KEYWORDS));
    const selectedFilters = JSON.parse(parseCookie(RECENTLY_APPLIED_FILTERS)) || [];
    const currentFilters = new Filters({ cityCode: selectedCityCode });
    const {[FILTER_ENTITIES.DEPOSIT]: depositFilterValue = NUMBER.FIVE_HUNDRED} = getAppliedFilterValues(appliedFilters) || {};

    const { payload: topFilterSearchUrl } = currentFilters.getListingPayload({
        selectedFilters,
        filters: allFilters
    });
    if (!preApproved) {
        params = `?${params}&${FILTER_ENTITIES.DEPOSIT}=${depositFilterValue}`;
    }
    try {
        const requestBody = {
            ...payload,
            recurringUser: !!secureToken,
            appointmentIdList: JSON.parse(parseCookie(AU_RECENTLY_VIEWD_KEY)),
            recentlySearched: searchedKeyword ? searchedKeyword.map(item => item.value) : [],
            ...topFilterSearchUrl && {topFilterSearchUrl}
        };
        const response = await b2cBffListingAU.fetchSearchPage(requestBody, secureToken, params);
        const {result = [], config} = response.data || {};
        let obj = {};
        result.forEach(item => (obj[item.type] = Array.isArray(item.templates) ? item.templates[0] : item.templates));
        /**
         * UNCOMMENT THE BELOW CODE TO GET RECENTLY APPLIED FILTER FROM API INSTEAD OF COOKIE
         * Change if (selectedFilters.length > 0) to if (personalizedFilters)
         */
        //const { personalizedFilters = {} } = obj;
        //if (personalizedFilters) {
        if (selectedFilters.length > 0) {
            //const { result: personalizedFiltersResult = {} } = personalizedFilters;
            //const parsedFilters = getFiltersData(personalizedFiltersResult, true);
            //const { appliedFilters = [] } = initAppliedFilters(parsedFilters.allFilters) || {};
            obj = {
                ...obj,
                personalizedFilters: selectedFilters
            };
        }

        dispatch(fetchSearchPagesSuccess({config, ...obj }));
    } catch (error) {
        dispatch(fetchSearchPagesFailure(error));
    }
};

const setRecentlyViewedData = (secureToken) => async () => {
    const recentlyViewedPayload = getRecentViewedWithTimestamp(AU_RECENTLY_VIEWD_TIMESTAMP) || {};
    const payload = {
        appointmentIds: recentlyViewedPayload
    };
    return ListingServiceAU.setRecentlyViewed(payload || {}, secureToken)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch(() => {
            return Promise.reject();
        });
};

const setMarketingCategory = (data) => ({
    type: Types.SET_MARKETING_CATEGORY,
    data
});

const updateListingType = (listingType = LISTING_TYPE.ALL_CARS) => ({
    type: Types.UPDATE_LISTING_TYPE,
    listingType
});

const setCurrentSelectedFilter = (data) => ({
    type: Types.SET_CURRENT_SELECTED_FILTER,
    data
});

const setListingFBEvents = (fbEventType) => ({
    type: Types.SET_LISTING_FB_EVENTS,
    fbEventType
});

const fetchTopFiltersInit = () => ({
    type: Types.FETCH_TOP_FILTERS_INIT,
    isLoading: true
});

const fetchTopFiltersuccess = (data) => ({
    type: Types.FETCH_TOP_FILTERS_SUCCESS,
    data,
    isLoading: false
});

const fetchTopFiltersFailure = () => ({
    type: Types.FETCH_TOP_FILTERS_FAILURE,
    isLoading: false
});

const fetchTopFilters = (params) => (dispatch, getState) => {
    dispatch(fetchTopFiltersInit());
    const { user: { secureToken, userGeoData = {} } } = getState();
    const queryParams = params || "";
    return ListingServiceAU.fetchTopFilters(secureToken, queryParams, userGeoData)
        .then((response) => {
            dispatch(fetchTopFiltersuccess(response.data));
            return Promise.resolve(response.data);
        })
        .catch(() => {
            dispatch(fetchTopFiltersFailure());
            return Promise.reject();
        });
};

const setTopFilter = (data) => ({
    type: Types.SET_TOP_FILTERS,
    data
});

const replaceSelectedFilter = (data) => ({
    type: Types.REPLACE_SELECTED_FILTERS,
    data
});
const updateCustomBudgetDropdownOption = (data) => ({
    type: Types.UPDATE_CUSTOM_BUDGET_DROPDOWN_OPTION,
    data
});
export {
    fetchFilters,
    updateSSRStatus,
    updateFilterTimestamp,
    updateSelectedOnScreenFilter,
    updateSelectedFilterSuggestionToDefault,
    updatePriceFilterType,
    updateFilterOptionData,
    updateAllFiltersVisibility,
    fetchListingCount,
    updateFilterTabType,
    updateSelectedSort,
    clearAllFilters,
    updateAppliedFilters,
    updateUrlOptions,
    updateAppliedSuggestions,
    updateSelectedSuggestions,
    clearSuggestions,
    updateAllSubFilterOptions,
    fetchCategoryFilters,
    updateAllSelectedFilterOptions,
    updateSelectedCategories,
    fetchSearchResult,
    updateSSRFiltersList,
    setMarketingCategory,
    updateListingType,
    setCurrentSelectedFilter,
    setListingFBEvents,
    fetchTopFilters,
    setTopFilter,
    replaceSelectedFilter,
    updateCustomBudgetDropdownOption,
    removeFilterBatch,
    setRecentlyViewedData
};
