import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_FILTERS
    FETCH_FILTERS_SUCCESS
    FETCH_FILTERS_FAILURE
    UPDATE_SSR_STATUS

    UPDATE_FILTER_TIMESTAMP

    UPDATE_APPLIED_FILTERS
    UPDATE_SELECTED_ON_SCREEN_FILTER
    UPDATE_SELECTED_FILTER_SUGGESTION_TO_DEFAULT
    UPDATE_FILTER_OPTION_DATA
    UPDATE_ALL_FILTERS_VISIBILITY

    FETCH_LISTING_COUNT
    FETCH_LISTING_COUNT_SUCCESS
    FETCH_LISTING_COUNT_FAILURE

    UPDATE_FILTER_TAB_TYPE
    UPDATE_SELECTED_SORT
    CLEAR_ALL_FILTERS

    UPDATE_APPLIED_FILTERS
    UPDATE_URL_OPTIONS

    UPDATE_APPLIED_SUGGESTIONS
    UPDATE_SELECTED_SUGGESTIONS

    CLEAR_SUGGESTIONS
    UPDATE_ALL_SUB_FILTER_OPTIONS

    FETCH_CATEGORY_FILTER_INIT
    FETCH_CATEGORY_FILTER_SUCCESS
    FETCH_CATEGORY_FILTER_FAILURE

    UPDATE_ALL_SELECTED_FILTER_OPTIONS
    UPDATE_SELECTED_CATEGORIES

    FETCH_SEARCH_PAGE_INIT
    FETCH_SEARCH_PAGE_SUCCESS
    FETCH_SEARCH_PAGE_FAILURE

    UPDATE_SSR_FILTERS_LIST
    SET_MARKETING_CATEGORY

    UPDATE_LISTING_TYPE
    SET_CURRENT_SELECTED_FILTER
    SET_LISTING_FB_EVENTS

    FETCH_TOP_FILTERS_INIT
    FETCH_TOP_FILTERS_SUCCESS
    FETCH_TOP_FILTERS_FAILURE

    SET_TOP_FILTERS
    REPLACE_SELECTED_FILTERS

    UPDATE_CUSTOM_BUDGET_DROPDOWN_OPTION
    REMOVE_FILTER_BATCH
`,
    {
        prefix: "filters/"
    }
);
