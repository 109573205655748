import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import Icon from "./images/bulb.svg";

const CheckCarsFromAllStates = ({heading, subHeading}) => {
    return (
        <div styleName={"styles.outer"}>
            <p styleName={"styles.heading"}><img src={Icon} />{heading}</p>
            <p styleName={"styles.subHeading"}>{subHeading}</p>
        </div>
    );
};

CheckCarsFromAllStates.propTypes = {
    heading: PropTypes.string,
    subHeading: PropTypes.string
};

export default CheckCarsFromAllStates;
