import React, {useState} from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

const ClpNewModal = loadable(() => import("../../shared/clp-new-modal/component"), {fallback: <div>Loading...</div>});

import Button from "../../shared/button";
import NoImpact from "./images/no-impact.png";
import { NUMBER } from "../../../constants/app-constants";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import { getPersonalisedTooltipInfoList, getTooltipInfoList } from "../../../utils/helpers/car-properties";

import OrangeInfo from "./images/orange-info.png";
import { trackMobileCustomEventsAU } from "../../../tracking";

import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";

const WeeklyPaymentModal = ({
    emiDetails,
    personalised,
    children,
    onCtaClicked,
    isListing,
    gtmFrom = ""
}) => {
    const [showModal, setShowModal] = useState(false);

    const {
        comparisonRate,
        tenure,
        interestRate,
        defaultDownPayment,
        emiPerWeek
    } = emiDetails || {};

    const handleClose = async(e) => {
        e.preventDefault();
        e.stopPropagation();
        await yieldToMain();
        setShowModal(!showModal);
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.INFO_ICON_REPAYMENT, {
            eventLabel: `Repayment_icon_${gtmFrom}`,
            eventAction: !showModal ? "Repayment_icon_opened" : "Repayment_icon_closed"
        });

    };

    const renderPara = (line) => {
        if (React.isValidElement(line)) {
            return line;
        }
        return <p dangerouslySetInnerHTML={{__html: line}} styleName={"styles.description"} />;
    };

    const paraMap = personalised ? getPersonalisedTooltipInfoList(emiDetails) : getTooltipInfoList(emiDetails);

    return (
        <React.Fragment>
            {isListing ?
                <img width="16" height="16" src={OrangeInfo} alt="info icon" onClick={handleClose} />
                :
                <div onClick={handleClose}>
                    {children}
                </div>
            }
            <ClpNewModal headerComponent={
                <p styleName={"styles.heading"}>Weekly payment of <span>${emiPerWeek}</span></p>
            }
            showModal={showModal}
            handleClose={handleClose}
            >
                <p styleName={"styles.calculated"}>Calculated based on the following:</p>
                <div styleName={"styles.tabsWrapper"}>
                    <div styleName={"styles.pills"}>
                        <p>{makePriceLabelRound(defaultDownPayment)} deposit</p>
                    </div>
                    <div styleName={"styles.pills"}>
                        <p>{tenure}-year term</p>
                    </div>
                    <div styleName={"styles.pills"}>
                        <p>${(interestRate && !isNaN(interestRate)) ? interestRate.toFixed(NUMBER.TWO) : ""}% interest rate</p>
                    </div>
                    <div styleName={"styles.pills"}>
                        <p>${(comparisonRate && !isNaN(comparisonRate)) ? comparisonRate.toFixed(NUMBER.TWO) : ""}% comparison rate</p>
                    </div>
                </div>
                {paraMap.map((item) => renderPara(item))}

                {!personalised  &&
                        <React.Fragment>
                            <p styleName={"styles.toKnow"}>To know your exact finance rates:
                            </p>
                            <Button text="Get pre-approval" onClick={onCtaClicked}/>
                            <p styleName={"styles.noImpact"}><img src={NoImpact} />No impact on credit score</p>
                        </React.Fragment>
                }

            </ClpNewModal>
        </React.Fragment>
    );
};

WeeklyPaymentModal.propTypes = {
    emiDetails: PropTypes.object,
    imageTitle: PropTypes.string,
    personalised: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    onCtaClicked: PropTypes.func,
    isListing: PropTypes.bool,
    gtmFrom: PropTypes.string
};

export default WeeklyPaymentModal;
