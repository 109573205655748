import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const OurLocationCard = ({heading, subHeading, redirectLink}) => {
    return (
        <a styleName={"styles.outer"} href={redirectLink} target="_blank">
            <p styleName={"styles.heading"}>{heading}</p>
            <p styleName={"styles.subHeading"}>{subHeading}</p>
        </a>
    );
};

OurLocationCard.propTypes = {
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    redirectLink: PropTypes.string
};

export default OurLocationCard;
