import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_CAR_LIST
    FETCH_CAR_LIST_SUCCESS
    FETCH_CAR_LIST_FAILURE

    FETCH_CONFIG
    FETCH_CONFIG_SUCCESS
    FETCH_CONFIG_FAILURE

    SEO_HEADER_MENU
    SEO_HEADER_MENU_SUCCESS
    SEO_HEADER_MENU_FAILURE

    SET_LOGO_URL

    FETCH_TOTAL_CAR_SUCCESS
    FETCH_TOTAL_CAR_FAILURE

    UPDATE_FINANCE_CENTRIC_ANIMATION

    FETCH_CMS_CONFIG_INIT
    FETCH_CMS_CONFIG_SUCCESS
    FETCH_CMS_CONFIG_FAILURE
`,
    {
        prefix: "home/"
    }
);
